<template>
  <CRow class="w-100 justify-content-center">
    <CCol col="12" sm="9" md="9" lg="9" xl="9">
      <CCard class="table-setting-style">
        <CCardHeader>
          <h3>Перегляд правил доступу</h3>
        </CCardHeader>
        <CCardBody>
          <CDataTable
              :items="actionsArray"
              :fields="$options.fields"
              hover
              striped
              bordered
          />

          <h4 class="mt-3">Правила по ролям</h4>
          <CNav variant="tabs">
            <CNavItem v-for="(actions, role) in role_actions" :key="role">
              <span @click="activeTab = role">{{ role }}</span>
            </CNavItem>
          </CNav>

          <div v-if="activeTab in role_actions">
            <CDataTable
                :items="actionsToArray(role_actions[activeTab])"
                :fields="$options.fields"
                hover
                striped
                bordered
            />
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>

import {rules_actions, role_actions} from "@/rules/accessUtils";

export default {
  name: "AdminViewRules",
  data: () => {
    return {
      actionsArray: [],
      activeTab: "aa",
      rules_actions,
      role_actions
    }
  },
  fields: [
    {key: 'action', label: 'Дія'},
    {key: 'description', label: 'Опис'},
  ],
  methods: {
    actionsToArray(actionsObj) {
      return Object.keys(actionsObj).map(action => ({action, description: rules_actions[action]}));
    }
  },
  mounted() {
    this.actionsArray = Object.keys(rules_actions).map(action => ({action, description: rules_actions[action]}));
  }
}
</script>
